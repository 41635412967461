import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Button, Tooltip, Typography } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { oo } from "../../element/theme";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { logError } from "../../element/error/sentry";
import { AuthContext } from "../../auth/AuthContextProvider";
import ky from "ky";
import endpoint from "../../rest/endpoint";

const initialTooltip = "Copy key to clipboard";
const instructionTxt = "This is the secret API key for your GitHub repository of your website. Do not share it. Do not commit it to a public repository. This key is used to link your settings from here with your created website.";

const FormApiKey = () => {
  const authContext = useContext(AuthContext);
  const firebase = authContext.getFirebase();
  let shownValue = "**********************************************";
  const [apiKeyValue, setApiKeyValue] = useState("");
  const [showKey, setShowKey] = useState(false);
  const [copiedValue, setCopiedValue] = useState(initialTooltip);

  const showHideToggle = () => {
    setShowKey(!showKey);
    if (apiKeyValue === "") {
      requestApiKey();
    }
  };

  const requestApiKey = async () => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken();
      const requestOptions = { json: { idToken: idToken } };
      const res = await ky.post(endpoint.userApiKey, requestOptions).json();
      setApiKeyValue(res.key);
    } catch (error) {
      logError(error);
    }
  };

  const copiedHandler = () => {
    setCopiedValue("Copied!");
    setTimeout(() => {
      setCopiedValue(initialTooltip);
    }, 5000);
  };

  if (showKey) {
    shownValue = apiKeyValue;
  }

  return (
    <ApiKeyContainer>
      <InstructionDiv>
        <Typography variant="body1">
          {instructionTxt}
        </Typography>
      </InstructionDiv>

      <ApiKeyField>
        <Typography variant="caption">{shownValue}</Typography>
      </ApiKeyField>

      <Tooltip title="Show / Hide key" placement="top">
        <ButtonSt variant="outlined" color="primary" startIcon={<VisibilityIcon />} size="small"
                  onClick={showHideToggle}>
          Show
        </ButtonSt>
      </Tooltip>
      <div style={{ height: "0.3rem" }} />
      <Tooltip title={copiedValue} placement="bottom">

        <CopyToClipboard text={apiKeyValue}
                         onCopy={copiedHandler}>
          <div>
            <ButtonSt variant="outlined"
                      color="primary"
                      startIcon={<FileCopyIcon />}
                      size="small"
                      disabled={apiKeyValue === ""}
            >
              Copy
            </ButtonSt>
          </div>
        </CopyToClipboard>
      </Tooltip>

    </ApiKeyContainer>
  );
};
export default FormApiKey;

const ApiKeyContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;
const InstructionDiv = styled.div`
  margin: 0.5rem 0;
`;
const ApiKeyField = styled.div`
  background-color: ${oo.grey8};
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  height: 2.4rem;
  width: 20.5rem;
  border-radius: 0.3rem;
  text-align: center;
`;
const ButtonSt = styled(Button)`
  margin: 0 0.3rem;
`;