import React from "react";
import PropTypes from "prop-types";
import theme from "../element/theme";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Paper, Typography } from "@material-ui/core";


const useStyles = makeStyles({
  root: {
    padding: "2rem",
    margin: props => props.margin,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.contrastText,
    width: "100%",
  },
});


const SettingsPaper = ({ children, heading, margin, anchorId }) => {
  const classes = useStyles({ margin });

  return (
    <Paper className={classes.root} elevation={1} margin={margin}>
      <Typography component="h3" variant="h5" gutterBottom id={anchorId}>{heading}</Typography>
      <Divider style={{ marginBottom: "0.4rem" }} />
      {children}
    </Paper>
  );
};
SettingsPaper.defaultProps = {
  margin: "1.5rem 0",
  anchorId: "",
};
SettingsPaper.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  margin: PropTypes.string,
  anchorId: PropTypes.string,
};
export default SettingsPaper;