import React from "react";
import SettingsSection from "./SettingsSection";
import SettingsPaper from "./SettingsPaper";
import DeleteAccount from "./create/FormDeleteAccount";
import ApiKey from "./create/FormApiKey";


const apiKeyTxt = [
  "Developer access",
  "Manage access between settings and your website",
  "API key",
];
const dangerZoneTxt = [
  "Danger zone",
  "Irreversible and destructive actions",
  "Delete user account",
];


const AccountSettings = () => {
  return (
    <>
      <SettingsSection heading={apiKeyTxt[0]} subheading={apiKeyTxt[1]}>
        <SettingsPaper heading={apiKeyTxt[2]}>
          <ApiKey />
        </SettingsPaper>
      </SettingsSection>

      <SettingsSection heading={dangerZoneTxt[0]} subheading={dangerZoneTxt[1]} noMarginBottom>
        <SettingsPaper heading={dangerZoneTxt[2]}>
          <DeleteAccount />
        </SettingsPaper>
      </SettingsSection>
    </>
  );
};
export default AccountSettings;
