import React, { useContext } from "react";
import { logError } from "../../element/error/sentry";
import { Button, Checkbox, FormControlLabel, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OoSpacing from "../../element/page-element/OoSpacing";
import { AuthContext } from "../../auth/AuthContextProvider";
import { oo } from "../../element/theme";
import ky from "ky";
import endpoint from "../../rest/endpoint";

const checkboxLabel = "Delete my user account and all of its data";
const formHelperText = [
  "Click the checkbox and the button to delete all your data from Oberion.",
  "Your GitHub account and code inside your repositories remain unchanged. You can log in via GitHub at any time to recreate your account at Oberion.",
  "You'll be automatically logged out after clicking delete.",
  "Any website you created with your GitHub account will remain in your GitHub account.",
];

const FormDeleteAccount = () => {
  const authContext = useContext(AuthContext);
  const firebase = authContext.getFirebase();
  const classes = useStyles();
  const [state, setState] = React.useState({ deleteData: false });

  const handleChange = name => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const deleteUser = async (event) => {
    event.preventDefault();

    try {
      const idToken = await firebase.auth().currentUser.getIdToken();
      const requestOptions = { json: { idToken: idToken } };
      await ky.delete(endpoint.user, requestOptions).json();
    } catch (error) {
      logError(error);
    } finally {
      if (typeof window !== "undefined" && window.localStorage) {
        // noinspection ES6MissingAwait
        window.localStorage.clear();
      }
      authContext.logUserOut();
    }
  };

  return (
    <form onSubmit={deleteUser}>

      <FormControlLabel label={checkboxLabel}
                        control={(<Checkbox checked={state.deleteData}
                                            onChange={handleChange("deleteData")}
                                            value="deleteData"
                                            color="primary"
                                            className={classes.checkboxRoot} />)} />
      <FormHelperText className={classes.formHelperTextRoot}>{formHelperText[0]}</FormHelperText>
      <FormHelperText className={classes.formHelperTextRoot}>{formHelperText[1]}</FormHelperText>
      <FormHelperText className={classes.formHelperTextRoot}>{formHelperText[2]}</FormHelperText>
      <FormHelperText className={classes.formHelperTextRoot}>{formHelperText[3]}</FormHelperText>
      <OoSpacing marginTop={1} />
      <Button variant="contained"
              color="primary"
              type="submit"
              disabled={!state.deleteData}
              classes={{ disabled: classes.disabled, root: classes.delete }}>
        Delete
      </Button>
    </form>
  );
};
export default FormDeleteAccount;

const useStyles = makeStyles(theme => ({
  checkboxRoot: {
    color: theme.palette.primary.main,
  },
  formHelperTextRoot: {
    color: oo.textColorOnLight,
    fontSize: "0.85rem",
  },
  disabled: {
    backgroundColor: theme.palette.grey["300"],
    color: theme.palette.grey["500"],
  },
  delete: {
    backgroundColor: oo.textErrorDark,
    color: oo.textColorOnDark,
    "&:hover": {
      backgroundColor: oo.textErrorDark,
    },
  },
}));