import React from "react";
import { Router } from "@reach/router";
import Layout from "../../components/element/layout/Layout";
import PageBanner from "../../components/element/page-element/PageBanner";
import LayoutMain from "../../components/element/layout/LayoutMain";
import SEO from "../../components/element/SEO";
import PrivateRoute from "../../components/auth/util/PrivateRoute";
import routes from "../../components/navigation/routes";
import AccountSettings from "../../components/user/AccountSettings";
import NewsletterBanner from "../../components/navigation/footer/NewsletterBanner";

const seo = { title: "Account Settings - Oberion" };
const newsletterMessage = "🚧 Hey, this isn't quite ready yet. Get notified when this is done.";

const Settings = () => {
  return (
    <Layout showNewsletter>
      <SEO {...seo} />
      <PageBanner heading="Account Settings" minHeight="0" />
      <NewsletterBanner path={routes.user.settings.to}
                        message={newsletterMessage}
                        padding="1.5rem 0.5rem"
                        extraButton />
      <LayoutMain>

        <Router>
          <PrivateRoute path={routes.user.settings.to} component={AccountSettings} />
        </Router>

      </LayoutMain>
    </Layout>
  );
};

export default Settings;
