import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { oo } from "../element/theme";

const SettingsSectionContainer = styled.section`
  width: 100%;
  max-width: ${oo.contentWidth}rem;
  margin: 0 auto ${props => props.marginBottom} auto;  
`;

const SettingsSection = ({ children, heading, subheading, noMarginBottom, id }) => {
  let marginBottom = `${oo.verticalSpacing * 1.25}rem`;
  if (noMarginBottom) marginBottom = "0";

  return (
    <SettingsSectionContainer marginBottom={marginBottom} id={id}>
      <Typography component="h2" variant="h3" gutterBottom>{heading}</Typography>
      <Typography component="p" variant="subtitle1" style={{ color: oo.textGrey }}>
        {subheading}
      </Typography>
      {children}
    </SettingsSectionContainer>
  );
};
SettingsSection.defaultProps = {
  noMarginBottom: false,
  id: null,
};
SettingsSection.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  noMarginBottom: PropTypes.bool,
  id: PropTypes.string,
};
export default SettingsSection;